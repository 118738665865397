// Styles
:root {
    --ct-bg-leftbar-brand: #c4dde9;
}

// Main styles

@import "./app.scss";

// Adminto Icons
@import "./icons.scss";
